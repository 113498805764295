export interface FacilityCategory {
  id: null;
  title: string;
  code: string;
}

export const facilitycategoryDefaults: FacilityCategory = {
  id: null,
  title: "",
  code: "",
};
